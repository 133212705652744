<template>
    <div class="m-3">
        <h3 class="cursor-pointer" v-html="name" @click="showValues = ! showValues"></h3>

        <div v-if="showValues" :class="wrapperClasses">
            <div class="my-2" v-for="item in values" :class="itemClasses(item)">
                <div :class="detailsClasses(item)">
                    <span class="block" v-html="`.${item.className}`"></span>
                    <small class="text-sm" v-html="item.value"></small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            wrapperClasses () {
                return {
                    'flex flex-wrap': this.mode == 'colors'
                }
            }
        },

        data: () => ({
            showValues: false
        }),

        name: 'ValueList',

        methods: {
            itemClasses (item) {
                if (this.mode == 'colors') {
                    return `${item.className} group w-8 h-8 m-2 relative`
                }

                if (this.mode == 'widths') {
                    return `${item.className} bg-grey h-2 group relative`
                }

                return item.className
            },

            detailsClasses (item) {
                if (this.mode == 'colors' || this.mode == 'widths') {
                    return 'bg-black text-white text-xs absolute mt-8 pin-l p-2 w-32 h-auto invisible group-hover:visible z-10'
                }

                return null
            }
        },

        props: {
            name: {
                required: true,
                type: String
            },
            mode: {
                default () {
                    return 'list'
                },
                required: false,
                type: String
            },
            values: {
                required: true,
                type: Array
            }
        }
    }
</script>