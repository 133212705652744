<template>
    <div :class="bodyClasses">
        <div class="border-b border-grey-light p-3 bg-grey-lightest">
            <div class="inline-block relative w-64">
                <select v-model="backgroundColorClass" class="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow">
                    <option v-for="item in backgroundColors" :value="item.className" v-html="`.${item.className}`"></option>
                </select>
                <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
            <div class="inline-block relative w-64">
                <select v-model="fontFamilyClass" class="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow">
                    <option v-for="item in fontFamilies" :value="item.className" v-html="`.${item.className}`"></option>
                </select>
                <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
            <div class="inline-block relative w-64">
                <select v-model="textColorClass" class="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow">
                    <option v-for="item in textColors" :value="item.className" v-html="`.${item.className}`"></option>
                </select>
                <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>

        </div>
        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-lightmb-2">Listaus</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Värit, fontit jne</h1>
        </div>
        <value-list name="Taustavärit" :values="backgroundColors" mode="colors"></value-list>
        <value-list name="Fontit" :values="fontFamilies"></value-list>
        <value-list name="Tekstivärit" :values="textColors"></value-list>
        <value-list name="Tekstikoot" :values="textSizes"></value-list>
        <value-list name="Leveydet" :values="widths" mode="widths"></value-list>

        <div class="bg-blue p-3 text-white">
            <h1 class="text-2xl text-base uppercase font-extrabold">Logo ja kuvat</h1>
        </div>
        <div class="p-6">
            <div class="flex">
                <div class="w-full md:w-1/3 p-3">
                    <img src="./img/logo.svg" class="max-w-full w-auto">
                </div>
                <div class="w-full md:w-1/3 bg-blue p-3">
                    <img src="./img/logo-neg.svg" class="max-w-full w-full w-auto">
                </div>
                <div class="w-full md:w-1/3 bg-white p-3">
                    <img src="./img/icon.svg" class="h-32 w-32">
                </div>
            </div>
        </div>

        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-lightmb-2">Tyyli</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Typografia</h1>
        </div>

        <div class="m-3 pt-3">
            <composer el="heading" classes="text-5xl text-base uppercase font-extrabold mb-2" content="Otsikko 1"></composer>
            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <ul class="mb-4">
                <li>Terve</li>
                <li>Terve</li>
                <li>Terve</li>
                <li>Terve</li>
            </ul>
            <composer el="heading" classes="text-3xl text-base uppercase font-extrabold mb-2 mt-4" content="Otsikko 2"></composer>
            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <composer el="heading" classes="text-2xl text-base uppercase font-extrabold mb-2 mt-4" content="Otsikko 3"></composer>
            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <composer el="heading" classes="text-xl text-base uppercase font-extrabold mb-2 mt-4" content="Otsikko 4"></composer>
            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <hr class="border-t border-grey-dark mb-4">
            <p class="mb-4">Perusteksti 16px</p>
            <p class="text-sm mb-4">Pieni teksti 14px</p>
            <p class="text-xs mb-4">Pienin teksti 12px</p>
        </div>

        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-lightmb-2">Grid</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Koot ja välit</h1>
        </div>
        <div class="m-3 pt-3">
            <p class="pb-4">Elementtien koko ja elementtien sisäiset välit (mm. nappien tekstin ja reunan väliset tilat) tulisi jatkossa toteuttaa leipätekstin (nyt 16px) kokoon pohjautuvina määritteinä (sori, nyt en keksinyt oikeita termejä :) ). Tällä hetkellä "käännös" vielä työn alla, mutta kehityksen helpottamiseksi oltava tietyt säännöt millä pelataan, keskustellaan ja pähkäillään yhdessä.</p>
            <p class="pb-4">Tavoitteena, että suunnitellaan ja määritellään mitkä koot käytössä mihinkin ja voidaan selkeästi kertoa kehitykselle mitä nimettyä kokoa käytetään, esim. "Kuponkikorttinosto: Layout 4x, Inset 2x. Kuponkikorttinoston nappi: InsetX 4x, InsetY 3x, MarginY 2x, Font 1x" jne. ja saadaan pidettyä pikselinviilauspyynnöt aisoissa (ehkä?).</p>
            <p class="pb-4">Esim. perusnappi: InsetX 3 = 12px, InsetY 2 = 8px, MarginT 2 = 8px, MarginB 4 = 16px, MarginX 2 = 8px</p>
            <div class="inline-block border border-grey-dark mb-6 bg-whitespace pt-2 pb-4 px-2">
                <div class="inline-block py-2 px-3 bg-blue-lighter text-white rounded font-light">
                    <div class="bg-blue">Sisältö</div>
                </div>
            </div>

            <h3>Koot</h3>
            <composer el="size" content="0.25rem = 4px == 1" classes="w-1 h-1 bg-blue-lightest border" />
            <composer el="size" content="0.5rem = 8px" classes="w-2 h-2 bg-blue-lightest border" />
            <composer el="size" content="0.75rem = 12px" classes="w-3 h-3 bg-blue-lightest border" />
            <composer el="size" content="1rem = 16px" classes="w-4 h-4 bg-blue-lightest border" />
            <composer el="size" content="1.5rem = 24px" classes="w-6 h-6 bg-blue-lightest border" />
            <composer el="size" content="2rem = 32px" classes="w-8 h-8 bg-blue-lightest border" />
            <composer el="size" content="3rem = 40px" classes="w-12 h-12 bg-blue-lightest border" />
            <composer el="size" content="4rem = 64px" classes="w-16 h-16 bg-blue-lightest border" />
        </div>

        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-lightmb-2">Komponentti</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Nappi</h1>
        </div>
        <div class="m-3 pt-3 mt-6">
            <div class="flex w-100 flex-col md:flex-row">
                <div class="w-full lg:w-1/3">
                    <small>Iso nappi</small>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-blue text-white hover:bg-blue-light rounded uppercase font-extrabold tracking-widest"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-blue-light text-white rounded uppercase font-extrabold tracking-widest cursor-not-allowed"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-green text-white hover:bg-green-dark  rounded uppercase font-extrabold tracking-widest"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-green-light text-white rounded uppercase font-extrabold tracking-widest cursor-not-allowed"></composer>

                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-transparent text-blue-dark hover:text-blue-darkest font-extrabold tracking-widest"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-3 px-6 bg-transparent text-blue-light font-extrabold tracking-widest cursor-not-allowed"></composer>
                </div>
                <div class="w-full lg:w-1/3">
                    <small>Perusnappi</small>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-2 px-3 bg-blue text-white hover:bg-blue-light rounded font-light"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-2 px-3 bg-green text-white hover:bg-green-dark rounded font-light"></composer>
                </div>
                <div class="w-full lg:w-1/3">
                    <small>Pieni nappi</small>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-1 px-2 bg-blue text-white hover:bg-blue-light rounded font-light text-sm"></composer>
                    <composer el="a" content="Klikkaa" classes="mb-2 inline-block py-1 px-2 bg-green text-white hover:bg-green-dark rounded font-light text-sm"></composer>
                </div>
                <div class="w-full lg:w-1/3">
                    <small>Erikoisnapit</small>
                    <composer el="a" content="Klikkaa" classes="rounded-full w-32 h-32 p-6 text-center block flex bg-green text-white hover:bg-green-dark uppercase font-extrabold tracking-widest items-center"></composer>

                </div>
            </div>
        </div>
        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-lightmb-2">Komponentti</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Lomake</h1>
        </div>
        <div class="m-3 pt-3">
            <div class="flex w-100 flex-wrap">
                <div class="w-full lg:w-1/3">
                    <small>Peruskenttä</small>
                    <composer el="input" classes="inline-block py-3 px-4 bg-grey-lightest text-grey-darker rounded mb-2 border border-grey-light focus:border-blue-lighter"></composer>
                </div>
                <div class="w-full lg:w-1/3">
                    <small>Oikein täytetty kenttä</small>
                    <composer el="input" content="Täytetty" classes="inline-block py-3 px-4 bg-grey-lightest text-grey-darker rounded mb-2 border border-green focus:border-green-lighter"></composer>
                </div>
                <div class="w-full lg:w-1/3">
                    <small>Hätähätä</small>
                    <composer el="input" content="Älä koske" classes="inline-block py-3 px-4 bg-red-lightest text-red-darker rounded  border border-red-light focus:border-red-lighter"></composer>
                    <p class="text-red-light text-xs mt-1">Nyt on jotain vähän vinksallaan tässä</p>
                </div>
                <div class="w-full border-top"></div>
                <div class="w-full lg:w-1/3">
                    <small>Erikoiskentät</small>
                    <div class="flex items-center relative">
                        <div class="absolute pin-t pin-b w-6 pt-6 text-center bg-blue text-white z-10 text-xs  uppercase rounded-tl rounded-bl">Fin</div>
                        <composer el="input" content="ABC-123" classes="w-32 inline-block py-3 px-4 pl-8 bg-grey-lightest font-mono text-grey-darker rounded border border-grey-light focus:border-blue-lighter"></composer>
                    </div>
                    <div class="flex items-center relative mt-3">
                        <input type="text" value="150" class="w-16 inline-block py-3 px-4 bg-grey-lightest text-grey-darker rounded-bl rounded-tl border border-grey-light focus:border-blue-lighter"></input>
                        <div class="inline-block py-3 px-4 bg-grey-lighter text-grey-darker rounded-tr rounded-br border border-grey-light border-l-0 focus:border-blue-lighter">000 km</div>
                    </div>

                    <div class="flex items-center relative mt-3">
                        <input type="text" value="" class="inline-block py-3 px-4 bg-grey-lightest text-grey-darker rounded-bl rounded-tl  border border-grey-light focus:border-blue-lighter"></input>
                        <button class="inline-block py-3 px-4 bg-green text-white rounded-tr rounded-br">Etsi</button>
                    </div>


                </div>
            </div>
        </div>

        <div class="bg-blue p-4 text-white">
            <div class="text-xs text-grey-light mb-2">Komponentti</div>
            <h1 class="text-4xl text-base uppercase font-extrabold">Navigaatiopalkit</h1>
        </div>
        <div class="p-3">
            <p class="mb-4">Vähän leikitty tyyleillä, ei mitään valmista...</p>
            <div class="h-128 bg-grey-lighter bg-whitespace items-end flex flex-col shadow-inner border border-blue-lightest" style="width: 400px">
                <div class="bg-blue flex w-full border-b border-blue-lighter text-center text-xs uppercase text-white mb-auto">
                    <div class="p-3 w-1/4">Lisää...</div>
                    <div class="flex-grow py-2">
                        <img src="./img/logo-markless.svg" class="w-2/3">
                    </div>
                    <div class="p-3 w-1/4">Oma sivu</div>
                </div>

                <div class="flex w-full border-t border-blue-lighter text-center text-xs uppercase bg-white">
                    <div class="p-3 w-1/4 border-green border-b-4 text-blue">Varaa</div>
                    <div class="p-3 w-1/4">Etsi</div>
                    <div class="p-3 w-1/4">Edut</div>
                    <div class="p-3 w-1/4">Ajankohtaista</div>
                </div>
            </div>
        </div>

        <p class="sticky pin-b pin-l pin-r z-50 text-red-dark p-4 border border-red-light bg-red-lighter mb-4">Työn alla, saa käyttää omalla vastuulla!</p>

    </div>
</template>

<script>
    import tailwindConfig from './../assets/tailwind'
    import Composer from './components/Composer'
    import ValueList from './components/ValueList'

    export default {
        components: {
            Composer, ValueList
        },

        computed: {
            bodyClasses () {
                return [
                    this.backgroundColorClass, this.fontFamilyClass, this.textColorClass
                ];
            },

            backgroundColors () {
                return this.mapToClasses(
                    'bg', tailwindConfig.backgroundColors
                );
            },

            fontFamilies () {
                return this.mapToClasses(
                    'font', tailwindConfig.fonts
                );
            },

            textColors () {
                return this.mapToClasses(
                    'text', tailwindConfig.textColors
                );
            },

            textSizes () {
                return this.mapToClasses(
                    'text', tailwindConfig.textSizes
                );
            },

            widths () {
                return this.mapToClasses(
                    'w', tailwindConfig.width
                );
            }
        },

        data: () => ({
            backgroundColorClass: 'bg-transparent',
            fontFamilyClass: 'font-sans',
            textColorClass: 'text-black',
            contentList: 'Klikkaa'
        }),

        name: 'DesignSystem',

        methods: {
            mapToClasses (prefix, values) {
                return Object.keys(values).map(key => {
                    return {
                        className: `${prefix}-${key}`,
                        value: values[key]
                    }
                })
            }
        }
    }
</script>