<template>
    <div class="relative">

        <div v-if="el == 'button'" class="inline-block">
            <button :class="classList">{{ contentList }}</button>
        </div>
        <div v-else-if="el == 'a'" class="inline-block">
            <a :class="classList">{{ contentList }}</a>
        </div>
        <div v-else-if="el == 'input'" class="inline-block">
            <input type="text" :class="classList" :value="contentList">
        </div>
        <div v-else-if="el == 'heading'" class="inline-block">
            <div :class="classList">{{ contentList }}</div>
        </div>
        <div v-else-if="el == 'size'" class="inline-block">
            <div :class="classList"></div>
            <div class="mb-4 mt-2 text-xs">{{ contentList }}</div>
        </div>
        <div class="group inline-block">
            <a class="text-grey py-2 text-sm">CSS</a>
            <div class="p-3 bg-grey-lightest invisible group-hover:visible z-10 absolute pin-l pin-r overflow-scroll">
                <pre class="border bg-grey-lightest rounded p-3 mb-3">{{ classList }}</pre>

                <pre class="border bg-grey-lightest rounded p-3">@apply {{ cssList }}</pre>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        computed: {
            classList () {
                return this.classes
            },
            cssList () {
                return this.classes.split(' ').map(item => `.${item}`).join('')
            },
            contentList () {
                return this.content
            },
        },

        name: 'Composer',

        props: {
            el: {
                required: true,
                type: String
            },
            classes: {
                required: true,
                type: String
            },
            content: {
                required: true,
                type: String
            }
        }
    }
</script>